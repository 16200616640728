import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import Customers from "components/ui/extended/Customers";
import TestimonialWithImageYari from "../../components/ui/extended/TestimonialWithImageYari";
import VideoPreview from "components/ui/base/VideoPreview";
import BookADemo from "components/ui/extended/BookADemo";
import TestimonialWithImageLaurentiu from "components/ui/extended/TestimonialWithImageLaurentiu";
import Awards from "components/ui/extended/Awards";
import PricingComponent from "components/ui/extended/Pricing";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";
import HorizontalStoriesMenu from "components/ui/base/HorizontalStoriesMenu";
import ReadPost from "components/ui/base/ReadPost";

interface BestIntercomAlternativePageProps {
  location: Location;
}

export default ({ location }: BestIntercomAlternativePageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      layoutSettings={{
        withMenu: false,
        withFooter: false,
        clickableLogo: false,
        ctaFocusId: "input-cta",
        mobileCta: true,
      }}
      location={location}
    >
      <SEO
        title={t("pages.try-customerly.best-intercom-alternative.seo.title")}
        description={t(
          "pages.try-customerly.best-intercom-alternative.seo.description"
        )}
        image={{
          relativePath: "meta/why-customerly-the-best-intercom-alternative.jpg",
          alt: "The Most Competitive Intercom Alternative You Were Hoping To Find Sooner",
        }}
      />

      <PlainHeaderWithVideo
        title={t("pages.try-customerly.best-intercom-alternative.title")}
        description={t(
          "pages.try-customerly.best-intercom-alternative.description"
        )}
        ctaRegister={{ show: true }}
        video={{
          path: "pages/try-customerly/best-intercom-alternative/inboxes.mp4",
        }}
      />

      <Awards />

      <TestimonialWithImageLaurentiu />

      <PricingComponent location={location} />

      <Customers />

      {/*Help Center self serve*/}
      <HorizontalStoriesMenu
        title={t("pages.customer-service-solution.helpCustomersStories.title")}
        paragraph={t(
          "pages.customer-service-solution.helpCustomersStories.paragraph"
        )}
        storiesPosition={"left"}
        elements={[
          {
            title: t(
              "pages.customer-service-solution.helpCustomersStories.1.title"
            ),
            description: t(
              "pages.customer-service-solution.helpCustomersStories.1.description"
            ),
            story: {
              relativePath: "pages/help-center/help-center-view.mov",
            },
          },
          {
            title: t(
              "pages.customer-service-solution.helpCustomersStories.2.title"
            ),
            description: t(
              "pages.customer-service-solution.helpCustomersStories.2.description"
            ),
            story: {
              relativePath:
                "components/customer-service-stories/in-chat-help-center.mov",
            },
          },
          {
            title: t(
              "pages.customer-service-solution.helpCustomersStories.3.title"
            ),
            description: t(
              "pages.customer-service-solution.helpCustomersStories.3.description"
            ),
            story: {
              relativePath:
                "pages/help-center/live-chat-help-center-autoreply.mov",
            },
          },
        ]}
      />

      <ReadPost slug={"intercom-alternatives"} />

      <VideoPreview
        title={t(
          "pages.try-customerly.best-intercom-alternative.automate.title"
        )}
        paragraph={t(
          "pages.try-customerly.best-intercom-alternative.automate.paragraph"
        )}
        storiesPosition={"right"}
        stories={[
          {
            relativePath:
              "pages/marketing-funnel/marketing-automation-funnel-template-builder.mp4",
          },
        ]}
      />

      {/*Help Center self serve*/}
      <HorizontalStoriesMenu
        title={t("pages.customer-service-solution.helpCustomersStories.title")}
        paragraph={t(
          "pages.customer-service-solution.helpCustomersStories.paragraph"
        )}
        storiesPosition={"left"}
        elements={[
          {
            title: t(
              "components.ui.extended.CustomerSatisfactionStories.feedback"
            ),
            story: {
              relativePath: "pages/surveys/in-chat-survey.mov",
            },
          },
          {
            title: t(
              "components.ui.extended.CustomerSatisfactionStories.learnHelp"
            ),
            story: {
              relativePath: "pages/nps/net-promoter-score.mov",
            },
          },
          {
            title: t(
              "components.ui.extended.CustomerSatisfactionStories.analyze"
            ),
            story: {
              relativePath: "pages/nps/nps-feedback.mov",
            },
          },
        ]}
      />

      <BookADemo
        id={"demo"}
        title={t("pages.try-customerly.best-intercom-alternative.demo.title")}
        description={t(
          "pages.try-customerly.best-intercom-alternative.demo.description"
        )}
      />

      <TestimonialWithImageYari />
    </GlobalWrapper>
  );
};
